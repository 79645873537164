<template>
  <div>
    <b-row>
      <b-col xl="12" lg="12" md="12" sm="12" cols="12">
        <b-card class="card-custom h-100" footer-tag="footer" footer-class="text-right">
          <b-row>
            <b-col xl="9" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1">User</label>

              <multiselect
                v-model="value"
                placeholder="Search on users"
                label="name"
                track-by="code"
                :options="options"
                :multiple="true"
                :taggable="true"
              ></multiselect>
            </b-col>
            <b-col xl="3" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <b-form-checkbox
                class="form-check-input ml-2 mt-10"
                id="exampleCheck1"
                v-model="allUsers"
                @change="selectAll()"
                ><label class="text-muted">All Users</label></b-form-checkbox
              >
            </b-col>
            <b-col xl="9" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1">Title</label>

              <b-form-input></b-form-input>
            </b-col>
            <b-col xl="9" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1">Notification Text</label>

              <b-form-input></b-form-input>
            </b-col>

            <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
              <label class="text-muted ml-1">Message</label>
              <ckeditor v-model="editorData" :config="editorConfig"></ckeditor
            ></b-col>
          </b-row>
          <template #footer>
            <b-button variant="primary" class="font-weight-bold px-10">
              Send Message
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CKEditor from 'ckeditor4-vue'
import Multiselect from 'vue-multiselect'

export default {
  name: 'notifications-new',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Notifications' }])
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo
    },
  },
  data() {
    return {
      selected: [],
      allUsers: false,
      value: [],
      options: [
        { name: 'Vue.js', code: 'vu' },
        { name: 'Javascript', code: 'js' },
        { name: 'Open Source', code: 'os' },
      ],
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      default_photo: '',
      current_photo: null,
    }
  },
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.current_photo = event.target.result
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.allUsers == false) {
          this.value = []
        } else {
          this.value = [...this.options]
        }
      })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.image-input-wrapper {
  width: 250px;
  height: 250px;
}
</style>
